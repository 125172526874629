import React from "react";
import UseData from "../Hooks/UseData";

const Footer = ({ bg, condition }) => {
  const { local } = UseData();
  return (
    <footer
      style={{
        background: `${
          local === "light" ? bg : condition ? "#212425" : "transparent"
        }`,
      }}
      className="overflow-hidden rounded-b-2xl"
    >
      <p className="text-tiny text-gray-lite text-center dark:text-color-910 pt-6 pr-6 pl-6">
        Any time frames or estimates that we give are contingent upon your full
        co-operation and complete and final content in photography for the work
        pages. During development there is a certain amount of feedback required
        in order to progress to subsequent phases. It is required that a single
        point of contact be appointed from your side and be made available on a
        daily basis in order to expedite the feedback process.
      </p>
      <p className="text-center py-6 text-gray-lite  dark:text-color-910">
        © {new Date().getFullYear()} Ian Andrew Macalisang. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
