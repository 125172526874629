import { InlineWidget } from "react-calendly";
import "react-toastify/dist/ReactToastify.css";

const CommonContact = ({ condition }) => {
  return (
    <div
      data-aos="fade"
      className={`${
        condition
          ? "mx-4 md:mx-[60px] p-4 md:p-16 dark:border-[#212425] dark:border-2"
          : "  dark:border-[#212425] dark:border-2 mb-16  md:p-[48px]  p-4  "
      } bg-color-810 rounded-xl dark:bg-[#111111] mb-[30px] md:mb-[60px]`}
    >
      <h3 className="text-4xl mb-9">
        <span className="text-gray-lite dark:text-[#A6A6A6] ">
          Let's succeed together
        </span>
        <br />
        <span className="font-semibold dark:text-white">
          in your startup journey!
        </span>
      </h3>

      {/* Form Start */}
      <InlineWidget
        url="https://calendly.com/ianmacalisang/60min"
        styles={{
          height: "1050px",
        }}
      />
      {/* Form Start */}
    </div>
  );
};

export default CommonContact;
